import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { S25EventOccurrencesComponent } from "./s25.event.occurrences.component";
import { OccurrenceDetailsComponent } from "./s25-event-occurrence/occ-table-components/occurrence.details.component";
import { OccurrenceActionComponent } from "./s25-event-occurrence/occ-table-components/occurrence.action.component";
import { S25OptModule } from "../../s25-opt/s25.opt.module";
import { S25EditableModule } from "../../s25-editable/s25.editable.module";
import { S25ItemModule } from "../../s25-item/s25.item.module";
import { S25ModalModule } from "../../s25-modal/s25.modal.module";
import { S25SliderModule } from "../../s25-slider/s25.slider.module";
import { S25TableModule } from "../../s25-table/s25.table.module";
import { OccurrenceAdditionalDetailsComponent } from "./s25-event-occurrence/occ-table-components/occurrence-additional-details/occurrence.additional.details.component";
import { OccurrenceDateComponent } from "./s25-event-occurrence/occ-table-components/occurrence.date.component";
import { OccurrenceSegmentComponent } from "./s25-event-occurrence/occ-table-components/occurrence.segment.component";
import { S25EventDetailsModule } from "../s25.event.details.module";
import { S25LoadingInlineModule } from "../../s25-loading/s25.loading.inline.module";
import { S25EventOccurrencesListComponent } from "./s25.event.occurrences.list.component";
import { PopoverModule } from "../../s25-popover/popover.module";
import { OccurrenceAddedTimeComponent } from "./s25-event-occurrence/occ-table-components/occurrence.added.time.component";
import { S25SimpleCollapseModule } from "../../s25-simple-collapse/s25.simple.collapse.module";
import { S25EventOccurrencesService } from "./s25.event.occurrences.service";
import { S25ObjectSearchModule } from "../../s25-object-search/s25.object.search.module";
import { OccurrenceSetForAllComponent } from "./s25-event-occurrence/occ-table-components/occurrence.set.for.all.component";
import { S25PipesModule } from "../../../pipes/s25.pipes.module";
import { S25CheckboxModule } from "../../s25-checkbox/s25.checkbox.module";
import { S25DeleteModule } from "../../s25-delete/s25.delete.module";
import { S25IconModule } from "../../s25-icon/s25.icon.module";
import { OccurrenceTimeComponent } from "./s25-event-occurrence/occ-table-components/occurrence.time.component";

@NgModule({
    declarations: [
        S25EventOccurrencesComponent,
        OccurrenceDetailsComponent,
        OccurrenceActionComponent,
        OccurrenceAdditionalDetailsComponent,
        OccurrenceDateComponent,
        OccurrenceSegmentComponent,
        S25EventOccurrencesListComponent,
        OccurrenceAddedTimeComponent,
        OccurrenceSetForAllComponent,
        OccurrenceTimeComponent,
    ],
    imports: [
        CommonModule,
        S25EditableModule,
        S25ItemModule,
        S25SliderModule,
        S25TableModule,
        S25ModalModule,
        S25OptModule,
        S25EventDetailsModule,
        S25LoadingInlineModule,
        PopoverModule,
        S25SimpleCollapseModule,
        S25ObjectSearchModule,
        S25PipesModule,
        S25CheckboxModule,
        S25DeleteModule,
        S25IconModule,
    ],
    exports: [
        S25EventOccurrencesComponent,
        OccurrenceDetailsComponent,
        OccurrenceActionComponent,
        OccurrenceAdditionalDetailsComponent,
        OccurrenceDateComponent,
        OccurrenceSegmentComponent,
        S25EventOccurrencesListComponent,
        OccurrenceAddedTimeComponent,
        OccurrenceSetForAllComponent,
        OccurrenceTimeComponent,
    ],
    providers: [S25EventOccurrencesComponent, S25EventOccurrencesService],
})
export class S25EventOccurrencesModule {}
