import { Injectable } from "@angular/core";
import { S25EventUtil } from "../models/s25.event";
import { EventService } from "../../../services/event.service";
import { EventMicroService } from "../../../services/event.micro.service";
import { S25Util } from "../../../util/s25-util";
import { EventMircotI, ExpandedInfoI, WsDataI } from "../EventMicroI";
import { CacheRepository } from "../../../decorators/cache.decorator";
import { S25Event } from "../EventMicroI";

@Injectable()
export class S25EventOccurrencesService {
    private _S25Event: S25Event;

    public get S25Event(): S25Event {
        return this._S25Event;
    }

    getOccurrences() {
        return S25EventUtil.getAllOccurrences(this._S25Event);
    }

    getProfiles() {
        return this._S25Event.profile;
    }

    addProfile(newProfile: any) {
        return S25EventUtil.addProfile(this._S25Event, newProfile);
    }

    constructor() {}

    async getEvent(eventId: number, force?: boolean) {
        let taskService = EventService.getEventIncludeCached;
        if (force) {
            CacheRepository.invalidateByService("EventMicroService", "getEventDetailById");
            taskService = EventService.getEventInclude;
        }
        return EventMicroService.getEventDetailById(S25Util.array.forceArray(eventId), "T", "all").then(
            async (data: EventMircotI) => {
                data.content.approval = await taskService(eventId, ["workflow"]);
                this._S25Event = await S25EventUtil.setFromWS(data.content);
                return this.S25Event;
            },
        );
    }
}
