import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
@Injectable({
    providedIn: "platform",
})
export class S25EventShareDataService {
    private selectedItems = new BehaviorSubject<number[]>([]);

    upateItems(e: number[]) {
        this.selectedItems.next(e);
    }

    getSelectedItems() {
        return this.selectedItems.asObservable();
    }

    resetItems() {
        this.selectedItems.next([]); // Reset to an empty array
    }
}
