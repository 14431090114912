import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    ChangeDetectorRef,
    ViewChild,
    ElementRef,
    ViewChildren,
    QueryList,
    ViewEncapsulation,
} from "@angular/core";
import { S25EventOccurrencesService } from "./s25.event.occurrences.service";
import { S25EventUtil } from "../models/s25.event";
import { S25Util } from "../../../util/s25-util";
import { S25ReservationUtil } from "../models/s25.reservation";
import { Debounce } from "../../../decorators/debounce.decorator";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { UserprefService } from "../../../services/userpref.service";
import { ObjectPermissionService } from "../../../services/object.permission.service";
import { S25TableComponent } from "../../s25-table/s25.table.component";
import { S25LoadingApi } from "../../s25-loading/loading.api";
import { S25EventOccurrencesListComponent } from "./s25.event.occurrences.list.component";
import { PopoverComponent } from "../../s25-popover/popover.component";
import { S25ItemI } from "../../../pojo/S25ItemI";
import { OptBean, S25OptComponent } from "../../s25-opt/s25.opt.component";
import { FlsService } from "../../../services/fls.service";
import { OccurrenceDateComponent } from "./s25-event-occurrence/occ-table-components/occurrence.date.component";
import { Bind } from "../../../decorators/bind.decorator";
import { S25ProfileUtil } from "../models/s25.profile";
import { EvOccMenuOutput } from "./ev-occ-menu/ev-occ-menu.component";
import { EventProfileMicroService, ProfileMinimalI } from "../../../services/event.profile.micro.service";
import { S25Reservation, ObjectType, S25RmReservation, S25RsReservation } from "../ReservationI";
import { S25Event } from "../EventMicroI";
import { S25Profile } from "../ProfileI";
import { S25Datefilter } from "../../s25-dateformat/s25.datefilter.service";
import { Event } from "../../../pojo/Event";
import State = Event.State.Ids;
import { Proto } from "../../../pojo/Proto";
import ISODateString = Proto.ISODateString;
import { EventMicroService } from "../../../services/event.micro.service";
import { ModalService } from "../../modal/modal.service";
import { EventDataMicroI } from "../EventMicroI";
import { EvOccMenuComponent } from "./ev-occ-menu/ev-occ-menu.component";
import { NotificationService } from "../../../services/notification.service";

/**
 * Parent component for event occurrences view
 */
@TypeManagerDecorator("s25-ng-event-occurrences")
@Component({
    selector: "s25-ng-event-occurrences",
    template: `
        @if (optInit) {
            <s25-ng-opt [modelBean]="optBean" (editModeChange)="onEditToggle($event)"></s25-ng-opt>
        }

        @if (init) {
            <!--
            <div class="floatRight">
                @if (!isExpandAll) {
                    <span (click)="showAll(true)">
                        <svg class="c-svgIcon" role="button">
                            <title>Expand</title>
                            <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#caret--caret-up"
                            ></use>
                        </svg>
                    </span>
                }
                @if (isExpandAll) {
                    <span (click)="showAll(false)" class="c-margin-left--single">
                        <svg class="c-svgIcon" role="button" id="collapeButton">
                            <title>Collapse</title>
                            <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#caret--caret-down"
                            ></use>
                        </svg>
                    </span>
                }
            </div> -->
            <div>
                @if (occurrencesListOriginal?.length > 0) {
                    <div class="occ-adj-wrapper">
                        <div class="prefs-wrapper">
                            @for (profile of event?.profile; track profile.itemId; let i = $index) {
                                <s25-ng-ev-prefs
                                    [eventId]="eventId"
                                    [panelIndex]="i"
                                    [eventName]="profile.itemName"
                                    [profileId]="profile.itemId"
                                ></s25-ng-ev-prefs>
                            }

                            <!-- if just one location/resource no need to have filter -->

                            <ng-container>
                                @if (event && allLocations && allLocations.length > 1) {
                                    <s25-ng-ev-colander
                                        [type]="'location'"
                                        [data]="allLocations"
                                        (modelValueChange)="onColenderChange($event, 'location')"
                                    ></s25-ng-ev-colander>
                                }

                                @if (event && allResources && allResources.length > 1) {
                                    <s25-ng-ev-colander
                                        [type]="'resource'"
                                        [data]="allResources"
                                        (modelValueChange)="onColenderChange($event, 'resource')"
                                    ></s25-ng-ev-colander>
                                }
                            </ng-container>
                        </div>
                        @if (!isSeparatedView) {
                            <div class="s25ph-title ng-binding">
                                <div class="ngBold occurrence-border">
                                    Event Occurrences
                                    <span class="dataView">(view as of {{ lastUpdate }})</span>
                                    <div class="floatRight setting-border">
                                        <s25-popover
                                            [modelBean]="{ popoverTemplate: settingTemplate }"
                                            [openTrigger]="'click'"
                                            [closeTrigger]="'click'"
                                            [placement]="'bottom-end'"
                                            class="ngInlineBlock floatRight"
                                        >
                                            <span>
                                                <svg class="c-svgIcon" role="button">
                                                    <title>event menu</title>
                                                    <use
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#settings--cog"
                                                    ></use>
                                                </svg>
                                            </span>
                                        </s25-popover>
                                        <ng-template #settingTemplate>
                                            <s25-ng-occ-menu
                                                [isSeparatedView]="isSeparatedView"
                                                [canEdit]="editMode"
                                                [profileId]="event?.profile[0].itemId"
                                                [multiProfiles]="multiProfiles"
                                                [startDate]="defaultStartDate"
                                                [endDate]="defaultEndDate"
                                                (modelValueChange)="onViewChange($event)"
                                            >
                                            </s25-ng-occ-menu>
                                        </ng-template>
                                    </div>
                                </div>

                                <s25-ng-event-occurrences-list
                                    [occurrencesList]="occurrencesList"
                                    [canEdit]="canEdit"
                                    [editMode]="editMode"
                                    (refreshF)="refresh()"
                                >
                                </s25-ng-event-occurrences-list>
                                @if (
                                    !multiProfiles &&
                                    event &&
                                    !showCommentAndAdditonalTimes &&
                                    !showLoading &&
                                    editMode &&
                                    canEditComment
                                ) {
                                    <div class="c-margin-top--single c-margin-left--single">
                                        <label>
                                            <b>Comments:</b>
                                            <s25-ng-editable-textarea
                                                [(val)]="event.profile[0].comment"
                                                [readOnly]="!canEdit"
                                                placeholder="Add comments"
                                                hasCommit="true"
                                                hasCancelButton="true"
                                                hasCommitButton="true"
                                                commitButtonText="Save"
                                                canelButtonText="Canel"
                                                (valChange)="onCommentChange(event.profile[0].itemId, $event)"
                                            >
                                            </s25-ng-editable-textarea>
                                        </label>
                                    </div>
                                    <occurrence-added-time
                                        [occ]="occurrencesList[0]"
                                        [readOnly]="!canEdit"
                                        [profileId]="event.profile[0].itemId"
                                    ></occurrence-added-time>
                                }
                            </div>
                        }
                        @if (isSeparatedView) {
                            <div class="s25ph-title">
                                @for (p of event.profile; track p.itemId; let idx = $index, last = $last) {
                                    @if (occurrencesListGroupBy[p.itemId]) {
                                        <div class="ngBold occurrence-border">
                                            <div class="segmentName">
                                                <s25-ng-editable-text
                                                    [allowEmpty]="false"
                                                    [val]="p.itemName"
                                                    [min]="2"
                                                    [max]="40"
                                                    [readOnly]="!editMode"
                                                    hasCommit="true"
                                                    hasCancelButton="true"
                                                    hasCommitButton="true"
                                                    commitButtonText="Save"
                                                    canelButtonText="Canel"
                                                    (valChange)="onProfileNameChange(p.itemId, p.itemName, $event)"
                                                ></s25-ng-editable-text>
                                            </div>
                                            <div class="floatRight setting-border">
                                                <s25-popover
                                                    [modelBean]="{ popoverTemplate: settingTemplate }"
                                                    [openTrigger]="'click'"
                                                    [closeTrigger]="'click'"
                                                    [placement]="'bottom-end'"
                                                    class="ngInlineBlock"
                                                >
                                                    <span>
                                                        <svg class="c-svgIcon" role="button">
                                                            <title>event menu</title>
                                                            <use
                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#settings--cog"
                                                            ></use>
                                                        </svg>
                                                    </span>
                                                </s25-popover>
                                                <ng-template #settingTemplate>
                                                    <s25-ng-occ-menu
                                                        [isSeparatedView]="isSeparatedView"
                                                        [canEdit]="editMode"
                                                        [profileId]="p.itemId"
                                                        [multiProfiles]="multiProfiles"
                                                        [startDate]="defaultStartDate"
                                                        [endDate]="defaultEndDate"
                                                        (modelValueChange)="onViewChange($event)"
                                                    >
                                                    </s25-ng-occ-menu>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <s25-ng-event-occurrences-list
                                            [occurrencesList]="occurrencesListGroupBy[p.itemId]"
                                            [editMode]="editMode"
                                            [canEdit]="canEdit"
                                            [isSeparatedView]="isSeparatedView"
                                            (refreshF)="refresh()"
                                        >
                                        </s25-ng-event-occurrences-list>
                                    }
                                    @if (
                                        event &&
                                        !showCommentAndAdditonalTimes &&
                                        !showLoading &&
                                        editMode &&
                                        canEditComment
                                    ) {
                                        <div class="comments">
                                            <label>
                                                <b>Comments:</b>
                                                <s25-ng-editable-textarea
                                                    [(val)]="p.comment"
                                                    [readOnly]="!canEdit && !canEditComment"
                                                    placeholder="Add comments"
                                                    hasCommit="true"
                                                    hasCancelButton="true"
                                                    hasCommitButton="true"
                                                    commitButtonText="Save"
                                                    canelButtonText="Canel"
                                                    (valChange)="onCommentChange(p.itemId, $event)"
                                                >
                                                </s25-ng-editable-textarea>
                                            </label>
                                        </div>
                                        <div class="additional-time">
                                            <occurrence-added-time
                                                [occ]="occurrencesListGroupBy[p.itemId] ?? [0]"
                                                [profileId]="p.itemId"
                                                [readOnly]="!canEdit"
                                            ></occurrence-added-time>
                                        </div>
                                    }
                                    @if (!last && !showLoading) {
                                        <ng-container>
                                            <hr class="solid" />
                                        </ng-container>
                                    }
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EventOccurrencesComponent implements OnInit {
    @ViewChild(S25TableComponent) tableComponent: S25TableComponent;
    @ViewChild(OccurrenceDateComponent) occDateComponent: OccurrenceDateComponent;
    @ViewChild(S25EventOccurrencesListComponent) occTable: S25EventOccurrencesListComponent;
    @ViewChildren("occurrence") occurrenceElements: QueryList<ElementRef>;
    @ViewChild(PopoverComponent) popoverChild: PopoverComponent;
    @ViewChild(S25OptComponent) optComponent: S25OptComponent;
    @ViewChild(EvOccMenuComponent) menuComponent: EvOccMenuComponent;

    @Input() eventId: number;

    event: S25Event;
    count: number = 0;
    occurrencesList: S25Reservation[];
    occurrencesListOriginal: S25Reservation[];
    init = false;
    isExpandAll?: boolean = false;
    optInit = false;
    dateFormat: string;
    timeFormat: string;
    canEdit: boolean = false;
    editMode: boolean;
    optBean: OptBean = {
        autoInit: false,
        refreshF: this.refresh.bind(this),
        hasOpt: true,
        hasEventStateChange: true,
        hasRefresh: true,
        hasEditEvent: true,
        hasSecurity: true,
        hasEditToggle: true,
        hasHelp: true,
        helpTopic: "view_eventsingle_detail",
        hasTimestamp: true,
    };
    multiProfiles: boolean = false;
    locationFilter: Filter = { filterType: "any", itemList: [] as any[] };
    resourceFilter: Filter = { filterType: "any", itemList: [] as any[] };
    isSeparatedView: boolean = false;
    occurrencesListGroupBy: any = [];
    lastUpdate: Date | string | number = S25Util.date.toS25ISODateTimeStr(new Date());
    showCommentAndAdditonalTimes: boolean = true;
    defaultStartDate: Date = new Date();
    defaultEndDate: Date = new Date();
    showLoading: boolean = false;
    canEditComment: boolean = false;
    allLocations: any[];
    allResources: any[];

    constructor(
        private occurrencesService: S25EventOccurrencesService,
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {}

    /**
     * Inits the component, the opt bean calls getData() so no need to do that ourselves.
     */
    async ngOnInit() {
        S25LoadingApi.init(this.elementRef.nativeElement);
        //Get preferences and security. Event data will be fetched by the opt bar
        await Promise.all([this.getPreferences(), this.getSecurity()]);
        this.configureOptBean();
        await this.getData();
        this.lastUpdate = S25Datefilter.transform(S25Util.date.toS25ISODateTimeStr(new Date()), this.timeFormat);
        this.optInit = true;
        S25LoadingApi.destroy(this.elementRef.nativeElement);
        this.cd.detectChanges();
    }

    configureOptBean() {
        this.optBean.itemId = this.eventId;
        this.optBean.itemTypeId = 1;
        this.optBean.canEdit = this.canEdit;
        this.cd.detectChanges();
    }

    async getPreferences() {
        const [dateFormat, timeFormat] = await Promise.all([
            UserprefService.getS25Dateformat(),
            UserprefService.getS25Timeformat(),
        ]);
        this.dateFormat = dateFormat;
        this.timeFormat = timeFormat;
        return;
    }

    async getSecurity() {
        const [olsCanEdit, fls] = await Promise.all([
            ObjectPermissionService.canEdit(this.eventId, 1),
            FlsService.getFls(),
        ]);
        this.canEdit = olsCanEdit && fls.WEBSECURITY === "F";
        this.canEditComment = fls.EVENT_TEXT === "F";
        return;
    }

    @Bind
    async getData(force?: boolean) {
        let [event, error] = await S25Util.Maybe(this.occurrencesService.getEvent(this.eventId, force));

        if (error) console.error(error);
        this.event = event;
        this.event.profile = event.profile.sort((a: S25Profile, b: S25Profile) => {
            return new Date(a.occurrenceDefn.initStartDt).getTime() - new Date(b.occurrenceDefn.initStartDt).getTime();
        });
        this.occurrencesList = this.occurrencesService.getOccurrences(); //S25EventUtil.getAllOccurrences(this.event);
        this.occurrencesListOriginal = S25Util.array.clone(this.occurrencesList);
        this.defaultStartDate = S25Util.deepCopy(this.occurrencesList[0].eventStart);
        this.defaultEndDate = S25Util.deepCopy(this.occurrencesList[0].eventEnd);

        if (this.event.profile.length > 1) {
            this.multiProfiles = true;
        }
        if (this.isSeparatedView) this.groupByProfile();
        if (this.event.state !== State.Cancelled && this.event.state !== State.Denied)
            this.showCommentAndAdditonalTimes = false;
        this.getAllObjs();
        if (!this.optBean.canEdit) this.init = true; // user has read event only set init here
        return;
    }

    @Debounce(300) // Avoid double calls
    async refresh() {
        //only force new data if component is already initialized that is true refresh vs triggered from initial load
        const forceRefresh = this.init;
        this.init = false;
        this.cd.detectChanges();
        this.lastUpdate = S25Datefilter.transform(S25Util.date.toS25ISODateTimeStr(new Date()), this.timeFormat);
        await this.getData(forceRefresh);
        this.init = true;
        this.cd.detectChanges();
    }

    async onCommentChange(profileId: number, newVal: any) {
        // update profile  comment
        let data: ProfileMinimalI = { comments: newVal };
        try {
            const ok = await EventProfileMicroService.updateProfileMinimal(this.eventId, profileId, data);
        } catch (e) {
            S25Util.showError(e);
        }
    }

    async addToAll(minutes: number) {
        S25EventUtil.getAllOccurrences(this.event).forEach((occ) => {
            occ.eventStart = S25Util.date.addMinutes(occ.eventStart, minutes);
            occ.eventEnd = S25Util.date.addMinutes(occ.eventEnd, minutes);
        });
        this.cd.detectChanges();
    }

    async addOccurrence(newRsrv?: S25Reservation) {
        if (!newRsrv) {
            const rsrv = await S25ReservationUtil.createReservation(
                S25Util.date.parse("2023-12-2T15:02"),
                S25Util.date.parse("2023-12-2T16:05"),
            );
            S25EventUtil.addOccurrence(rsrv, this.event.profile[0]);
        } else {
            S25EventUtil.addOccurrence(newRsrv, this.event.profile[0]);
        }
        this.cd.detectChanges();
    }

    showAll(e: boolean) {
        this.isExpandAll = !this.isExpandAll;
        const elements: any = document.querySelectorAll(".occurrence-date-wrapper");
        for (let i = 0; i < elements.length; ++i) {
            elements[i].click();
        }
    }

    onEditToggle(e: boolean) {
        this.editMode = e;
        if (this.occTable) {
            //opt bar fetches preference value and emits editModeChange before occTable is initialized
            this.occTable.editMode = e;
            this.init && this.occTable.refresh();
        }
    }

    async onColenderChange(filterCriteria: Filter, type: ObjectType) {
        type === "location" ? (this.locationFilter = filterCriteria) : (this.resourceFilter = filterCriteria);
        if (this.locationFilter.itemList.length > 0 && this.resourceFilter.itemList.length > 0) {
            // filtered locations and resource
            let locList = this.filterItemsByName(filterCriteria, this.occurrencesListOriginal, type);
            let resList: any[] = this.filterItemsByName(filterCriteria, locList, type);
            this.occurrencesList = resList;
        } else if (this.locationFilter.itemList.length === 0) {
            //just filterd resources
            let resList = this.filterItemsByName(filterCriteria, this.occurrencesListOriginal, type);
            this.occurrencesList = resList;
        } else if (this.resourceFilter.itemList.length === 0) {
            //just filtered locations
            let locList = this.filterItemsByName(filterCriteria, this.occurrencesListOriginal, type);
            this.occurrencesList = locList;
        }
        this.occurrencesList = S25Util.array.unique(this.occurrencesList); // be sure no duplicate
        if (this.isSeparatedView) this.groupByProfile();

        this.occTable.occurrencesList = this.occurrencesList;
        this.occTable.refresh();
        this.cd.detectChanges();
    }

    filterItemsByName(filterCriteria: Filter, filterData: S25Reservation[], type: ObjectType) {
        // fitler all or any
        if (filterCriteria.filterType === "all") {
            return this.filterItemsByNameAll(filterData, type);
        } else {
            return this.filterItemsByNameAny(filterData, type);
        }
    }

    filterItemsByNameAll(filterData: S25Reservation[], type: ObjectType) {
        let tempList: any = [];
        let newList: any = [];
        if (type === "location") {
            filterData.forEach((occ: S25Reservation) => {
                tempList = occ.locations.map((location: any) => ({
                    draft: location.draft.filter((item: any) => this.locationFilter.itemList.includes(item.itemName)),
                    requested: location.requested.filter((item: any) =>
                        this.locationFilter.itemList.includes(item.itemName),
                    ),
                    reserved: location.reserved.filter((item: any) =>
                        this.locationFilter.itemList.includes(item.itemName),
                    ),
                }));

                if (
                    tempList[0]?.draft.length > 0 ||
                    tempList[0]?.requested.length > 0 ||
                    tempList[0]?.reserved.length > 0
                ) {
                    newList.push(occ);
                }
            });
        } else {
            filterData.forEach((occ: any) => {
                tempList = occ.resources.map((resource: any) => ({
                    draft: resource.draft.filter((item: any) => this.resourceFilter.itemList.includes(item.itemName)),
                    requested: resource.requested.filter((item: any) =>
                        this.resourceFilter.itemList.includes(item.itemName),
                    ),
                    reserved: resource.reserved.filter((item: any) =>
                        this.resourceFilter.itemList.includes(item.itemName),
                    ),
                }));
                if (
                    tempList[0]?.draft.length > 0 ||
                    tempList[0]?.requested.length > 0 ||
                    tempList[0]?.reserved.length > 0
                ) {
                    newList.push(occ);
                }
            });
        }
        return newList;
    }

    filterItemsByNameAny(filterData: S25Reservation[], type: ObjectType) {
        let tempList: any = [];
        let newList: any = [];
        if (type === "location") {
            filterData.forEach((occ: S25Reservation) => {
                tempList = occ.locations.filter((location: any) => ({
                    draft: location.draft.every((item: any) => this.locationFilter.itemList.includes(item.itemName)),
                    requested: location.requested.every((item: any) =>
                        this.locationFilter.itemList.includes(item.itemName),
                    ),
                    reserved: location.reserved.every((item: any) =>
                        this.locationFilter.itemList.includes(item.itemName),
                    ),
                }));

                if (
                    tempList[0]?.draft.length > 0 ||
                    tempList[0]?.requested.length > 0 ||
                    tempList[0]?.reserved.length > 0
                ) {
                    newList.push(occ);
                }
            });
        } else {
            filterData.forEach((occ: S25Reservation) => {
                tempList = occ.resources.filter((resource: S25ItemI) => ({
                    draft: resource.draft.every((item: S25ItemI) =>
                        this.resourceFilter.itemList.includes(item.itemName),
                    ),
                    requested: resource.requested.every((item: S25ItemI) =>
                        this.resourceFilter.itemList.includes(item.itemName),
                    ),
                    reserved: resource.reserved.every((item: S25ItemI) =>
                        this.resourceFilter.itemList.includes(item.itemName),
                    ),
                }));

                if (
                    tempList[0]?.draft.length > 0 ||
                    tempList[0]?.requested.length > 0 ||
                    tempList[0]?.reserved.length > 0
                ) {
                    newList.push(occ);
                }
            });
        }
        return newList;
    }

    async onViewChange(e: EvOccMenuOutput) {
        this.isSeparatedView = e.isSeparatedView;
        if (e.addNew) await this.addProfile(null, e.data.startDate, e.data.endDate);

        if (this.isSeparatedView) this.groupByProfile();

        if (e.isDelete) await this.deleteProfile(e.data.profileId);

        this.cd.detectChanges();
    }

    groupByProfile() {
        this.occurrencesListGroupBy = S25Util.array.groupBy(this.occurrencesList, (item) => item.profileId);
    }

    @Debounce(300) // Avoid double calls
    async addProfile(sourceProfileId?: number, startDate?: Date, endDate?: Date) {
        let newProfile: S25Profile;
        if (!sourceProfileId) {
            newProfile = await S25ProfileUtil.createEmptyProfile(startDate, endDate);
            let newRsrv = await S25ReservationUtil.createReservation(startDate, endDate, newProfile.itemId);
            S25ProfileUtil.addReservation(newProfile, newRsrv);
        } else {
            //grab the profile of the sourceProfileId
            const sourceProfile = this.event.profile.find((p) => p.itemId === sourceProfileId);
            newProfile = await S25ProfileUtil.duplicateProfile(sourceProfile);
        }

        this.occurrencesService.addProfile(newProfile);

        let normalizeData = await S25ProfileUtil.normalizeProfileWSData(
            this.event,
            S25Util.array.forceArray(newProfile),
        );
        let data = normalizeData[0].data;
        this.setLoading(true);
        const [ok, error] = await S25Util.Maybe(EventMicroService.microPutEventDetail(data, this.eventId));
        if (ok) {
            if (ok?.content?.errors || ok?.content?.messages) {
                for (let error of ok?.content?.errors || ok?.content?.messages) {
                    NotificationService.post(error.message);
                }
            } else {
                this.occurrencesList = this.occurrencesService.getOccurrences();
                this.multiProfiles = true;
                this.cd.detectChanges();
            }
        }
        if (error) return S25Util.showError(error, "There was an error while attempting to copy this reservation.");
        this.setLoading(false);
    }

    async onProfileNameChange(profileId?: number, initVal?: string, newVal?: string) {
        let data: ProfileMinimalI = { name: newVal };
        if (initVal && newVal && initVal !== newVal)
            // update profile name
            try {
                const ok = await EventProfileMicroService.updateProfileMinimal(this.eventId, profileId, data);
            } catch (e) {
                S25Util.showError(e);
            }
    }

    @Debounce(300) // Avoid double calls
    async deleteProfile(sourceProfileId?: number) {
        let dialogData = ModalService.dialogType(
            "Yes No",
            {
                message: "Are you sure you want to delete this segment?",
                title: "Confirm Deletion",
            },
            "No",
        );
        await ModalService.modal("dialog", dialogData);
        if (dialogData.answer !== 1) return; // User answered no

        // keep the codes for event micor service support remove profile Id
        /*
        let payload: EventDataMicroI = {
            items: [
                {
                    kind: "event",
                    id: this.eventId,
                    profiles: [
                        {
                            profileId: sourceProfileId,
                            remove: {
                                profileId: sourceProfileId,
                            },
                        },
                    ],
                },
            ],
        };
        const [ok, error] = await S25Util.Maybe(EventMicroService.microPutEventDetail(payload, this.eventId));
        */
        const [ok, error] = await S25Util.Maybe(EventProfileMicroService.deleteProfile(this.eventId, sourceProfileId));
        if (ok) {
            this.event.profile = this.event.profile.filter((profile) => profile.itemId !== sourceProfileId);
            this.event.profile.length > 1 ? (this.multiProfiles = true) : (this.multiProfiles = false);
            this.occurrencesList = this.occurrencesService.getOccurrences();
            this.cd.detectChanges();
        }
        if (error) return S25Util.showError(error, "There was an error while attempting to delete this segment.");
    }

    setLoading(yes: boolean) {
        this.showLoading = yes;
        if (yes) {
            S25LoadingApi.init(this.elementRef.nativeElement);
        } else {
            S25LoadingApi.destroy(this.elementRef.nativeElement);
        }
        this.cd.detectChanges();
    }

    async getAllObjs() {
        let locationArr: S25RsReservation[] = [];
        let resourceArr: S25RsReservation[] = [];
        let allLocations: S25RmReservation[] = [];
        let allResources: S25RmReservation[] = [];
        for (let occ of this.occurrencesListOriginal) {
            if (occ.locations.length > 0) {
                locationArr = S25ReservationUtil.getObjectsFlat(occ.locations[0]);
                allLocations = allLocations.concat(locationArr);
            }
            if (occ.resources.length > 0) {
                resourceArr = S25ReservationUtil.getObjectsFlat(occ.resources[0]);
                allResources = allResources.concat(resourceArr);
            }
        }
        this.allLocations = S25Util.array.unique(allLocations);
        this.allResources = S25Util.array.unique(allResources);
    }
}

export type Filter = { filterType: "any" | "all"; itemList: string[] };
